
import { defineComponent, ref, watchEffect, inject, onMounted, onUnmounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { Apollo, Notify } from '@/core/services';
import { GET_PRICE_GROUP_PRODUCTS, SEARCH_PRICE_GROUP_PRODUCTS } from '@/modules/catalog/products/graphql/Queries';
import { UPDATE_SPECIAL_PRICE } from '@/modules/catalog/products/graphql/Mutations';
import Search from '../../../../components/search/Search.vue';
import Table from '../../../../components/Table/Table.vue';
import InnerLoader from '../../../../components/InnerLoader.vue';
import { dropdown_handler } from '../../../../core/helpers/dropdownHandler';
import { useRoute } from 'vue-router';

export default defineComponent({
    name: 'categories-list',
    components: { Search, Table, InnerLoader },
    setup() {
        const route = useRoute();
        const i18n = useI18n();
        const store = useStore();
        const loader = ref(false);
        const exist = ref(false);
        const loading = ref(false);
        const productsData: any = ref([]);
        const filteredProductsData: any = ref([]);
        const unFilteredProductsData: any = ref([]);
        const pagination = ref({}) as Record<any, any>;
        const system_locale = ref();
        const emitter: any = inject('emitter');
        const clickHandler = ref() as Record<any, any>;
        const currentPage = ref();
        const permissions = store.getters.getPermissions;
        const activeIndex = ref(-1);
        //const routeParam = ref(route.params.id);
        const routeParam = ref(0);
        const columnName = ref(null);
        const columnSort = ref(null);

        const searchQuery = ref(false);
        const columns = ref([
            {
                label: 'message.PRODUCT_NUMBER',
                key: 'economic_number',
                sorting: true
            },
            {
                label: 'message.NAME',
                key: 'name',
                sorting: true
            },
            {
                label: 'message.SALE_PRICE',
                key: 'sale_price',
                textAlignment: 'end'
                //sorting: true
            },
            {
                label: 'message.SPECIAL_PRICE',
                key: 'special_price'
                //sorting: true
            }
        ]);

        const filterOBject = ref([
            {
                label: 'message.NAME',
                value: 'name'
            },
            {
                label: 'message.PRODUCT_NUMBER',
                value: 'model'
            }
        ]);

        watchEffect(() => {
            system_locale.value = i18n.fallbackLocale.value;
            routeParam.value = Number(localStorage.getItem('pg_id'));
        });

        const dropdownHandler = (index, id) => {
            activeIndex.value = index === id ? null : id;
        };
        const handleClickOutside = event => {
            if (!event.target.closest('.action-btn')) {
                activeIndex.value = -1;
            }
        };

        const handleSortingPrice = (products, type) => {
            if (type != null && type == true) {
                productsData.value = products.sort((a, b) => a.sale_price - b.sale_price);
            } else if (type != null && type != true) {
                productsData.value = products.sort((a, b) => b.sale_price - a.sale_price);
            }
        };

        const handleSortingSpecialPrice = (products, type) => {
            if (type != null && type == true) {
                productsData.value = products.sort((a, b) => a.special_price - b.special_price);
            } else if (type != null && type != true) {
                productsData.value = products.sort((a, b) => b.special_price - a.special_price);
            }
        };

        const handleSortingName = (products, type) => {
            if (type != null && type == true) {
                productsData.value = products.sort((a, b) => {
                    const aName = JSON.parse(a.name.name)[system_locale.value]?.name || '';
                    const bName = JSON.parse(b.name.name)[system_locale.value]?.name || '';
                    return aName.localeCompare(bName);
                });
            } else if (type != null && type != true) {
                productsData.value = products.sort((a, b) => {
                    const aName = JSON.parse(a.name.name)[system_locale.value]?.name || '';
                    const bName = JSON.parse(b.name.name)[system_locale.value]?.name || '';
                    return bName.localeCompare(aName);
                });
            }
        };

        const handleSorting = (page, reload, colName, type) => {
            loader.value = true;
            colName == 'Sale Price' ? handleSortingPrice(productsData.value, type) : '';
            colName == 'Special Price' ? handleSortingSpecialPrice(productsData.value, type) : '';
            colName == 'Name' ? handleSortingName(productsData.value, type) : '';
            loader.value = false;
        };

        const get_price_group_products = (page = 1, reload = false, colName = null, type = null, id = Number(routeParam.value)) => {
            //if (colName != null) columnName.value = colName;
            //if (type != null) columnSort.value = type;
            loader.value = true;
            columnName.value = null;
            columnSort.value = null;
            Apollo.watchQuery({
                query: GET_PRICE_GROUP_PRODUCTS,
                errorPolicy: 'all',
                fetchPolicy: 'network-only',
                nextFetchPolicy: 'cache-and-network',
                variables: {
                    page: page,
                    limit: 10,
                    id: id
                    //col: colName,
                    //type: type == true ? 'asc' : 'desc'
                }
            }).subscribe(({ data, errors }) => {
                if (errors) {
                    loader.value = false;
                }

                searchQuery.value = false;
                const searchData = store.getters.getSearchData;
                productsData.value = [];
                unFilteredProductsData.value = [];
                filteredProductsData.value = [];
                clickHandler.value = get_price_group_products;
                reload == true ? (currentPage.value = 1) : false;

                data.price_group_products.data.forEach((element, index) => {
                    exist.value = false;
                    element.product_to_price_groups_admin.forEach(ele => {
                        if (ele.price_group_id == routeParam.value) {
                            filteredProductsData.value.push({
                                economic_number: element?.economic_number,
                                name: element?.description,
                                product_id: element?.id,
                                price_group_id: ele?.price_group_id,
                                sale_price: element?.product_to_prices[0]?.price,
                                special_price: ele?.special_price_original
                            });
                            exist.value = true;
                            return true;
                        }
                    });
                    if (exist.value == false) {
                        unFilteredProductsData.value.push({
                            economic_number: element?.economic_number,
                            name: element?.description,
                            product_id: element?.id,
                            price_group_id: routeParam.value,
                            sale_price: element?.product_to_prices[0]?.price,
                            special_price: element?.special_price_original ? element?.special_price_original : null
                        });
                    }
                });

                productsData.value = filteredProductsData.value.concat(unFilteredProductsData.value);

                pagination.value = data.price_group_products;
                loader.value = false;
            });
        };

        const handleCurrentChange = (num: number) => {
            clickHandler.value(num);
        };

        const searchHandler = (page = 1, reload = false, colName = null, type = null, id = Number(routeParam.value)) => {
            if (colName != null) columnName.value = colName;
            if (type != null) columnSort.value = type;
            const searchData = store.getters.getSearchData;
            loader.value = true;
            Apollo.watchQuery({
                query: SEARCH_PRICE_GROUP_PRODUCTS,
                errorPolicy: 'all',
                fetchPolicy: 'network-only',
                nextFetchPolicy: 'cache-and-network',
                variables: {
                    page: page,
                    limit: 10,
                    id: id,
                    filter: searchData?.data?.select,
                    search_key: searchData?.data?.input,
                    locale: system_locale.value,
                    col: columnName.value,
                    type: columnSort.value == true ? 'asc' : 'desc'
                }
            }).subscribe(({ data, errors }) => {
                if (errors) {
                    loader.value = false;
                }

                searchQuery.value = true;
                productsData.value = [];
                unFilteredProductsData.value = [];
                filteredProductsData.value = [];
                clickHandler.value = searchHandler;

                data.search_price_group_products.data.forEach((element, index) => {
                    exist.value = false;
                    element.product_to_price_groups_admin.forEach(ele => {
                        if (ele.price_group_id == routeParam.value) {
                            filteredProductsData.value.push({
                                economic_number: element?.economic_number,
                                name: element?.description,
                                product_id: element?.id,
                                price_group_id: ele?.price_group_id,
                                sale_price: element?.product_to_prices[0]?.price,
                                special_price: ele?.special_price_original
                            });
                            exist.value = true;
                            return true;
                        }
                    });
                    if (exist.value == false) {
                        unFilteredProductsData.value.push({
                            economic_number: element?.economic_number,
                            name: element?.description,
                            product_id: element?.id,
                            price_group_id: routeParam.value,
                            sale_price: element?.product_to_prices[0]?.price,
                            special_price: element?.special_price_original ? element?.special_price_original : null
                        });
                    }
                });

                productsData.value = filteredProductsData.value.concat(unFilteredProductsData.value);

                pagination.value = data.search_price_group_products;
                loader.value = false;
            });
        };

        const updatePosition = (old_price: number, new_price: number, product_id: number, price_group_id: number) => {
            if (new_price <= 0) {
                Notify.error(i18n.t('message.PRICE_MUST_BE_GREATER_THAN_0'));
                return;
            }
            if (old_price == new_price) {
                return;
            }

            const searchData = store.getters.getSearchData;

            loader.value = true;

            Apollo.mutate({
                mutation: UPDATE_SPECIAL_PRICE,
                variables: {
                    price: new_price,
                    product_id: product_id,
                    price_group_id: Number(price_group_id)
                },
                update: (store, { data: { update_special_price } }) => {
                    loader.value = false;
                    if (update_special_price) {
                        searchHandler(pagination.value.current_page, false, columnName.value, columnSort.value, Number(routeParam.value));
                        Notify.success(`${i18n.t('message.SPECIAL_PRICE_CHANGED_SUCCESSFULLY')}`);
                    }
                }
            }).catch(e => {
                console.log(e);
                loader.value = false;
            });
        };

        onMounted(() => {
            get_price_group_products(1, false, null, null, Number(routeParam.value));
            document.addEventListener('click', handleClickOutside);
        });

        const handleEdit = (category: Record<any, any>) => {
            // category = ({...category, current_page: pagination.value.current_page});
            emitter.emit('editCategoryAction', category);
        };

        const capitalizeFirstLetter = category => {
            const lowercasedText = category.toLowerCase();
            return lowercasedText.charAt(0).toUpperCase() + lowercasedText.slice(1);
        };

        return {
            pagination,
            filterOBject,
            system_locale,
            productsData,
            currentPage,
            permissions,
            columns,
            loading,
            loader,
            activeIndex,
            capitalizeFirstLetter,
            // exportFile,
            searchHandler,
            handleCurrentChange,
            // get_categories,
            handleEdit,
            // handleDelete,
            updatePosition,
            dropdownHandler,
            handleClickOutside,
            routeParam,
            get_price_group_products,
            handleSorting
        };
    }
});
