
import { defineComponent, onMounted } from "vue";
import { setPageHeader } from "@/core/helpers/toolbar";
import AssignmentPriceGroup from "@/modules/customer/price-groups/components/AssignmentPriceGroup.vue";
export default defineComponent({
	components: {
		AssignmentPriceGroup,
	},
	setup() {
		onMounted(() => {
			setPageHeader({
				title: "message.PRICE_GROUP_ASSIGNMENT",
				actionButton: null,
				breadCrumbs: [
					{ name: "message.HOME", link: "/dashboard" },
					{ name: "message.CUSTOMERS", link: "#" },
					{ name: "message.PRICE_GROUP", link: "/customer/price-groups" },
					{ name: "message.ASSIGNMENT message.PRICE_GROUP" },
				],
			});
		});
	},
});
